(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:EventsCtrl
   *
   * @description
   *
   */
  angular
    .module('events')
    .controller('EventsCtrl', EventsCtrl);

  function EventsCtrl(events) {
    var vm = this;
    vm.ctrlName = 'EventsCtrl';

    vm.events = events;
  }
}());
